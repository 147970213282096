import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import Notifications from './Notifications'

function Header()
{
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = async () => {
        setMenuOpen( !menuOpen )
    }

    return (
        <>
            <div className={"fixed top-0 left-0 z-40 w-64 h-screen p-4 overflow-y-auto transition-transform bg-white dark:bg-gray-950 " + (menuOpen ? 'transform-none' : '-translate-x-full') } tabIndex="-1">
                <button onClick={toggleMenu} type="button" className="inline-flex rounded-full p-1 text-gray-900 dark:text-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 focus:outline-none absolute top-2.5 end-2.5" >
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 20 20" aria-hidden="true">
                        <path fill="currentColor" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                </button>
                <div className="pt-6 pb-2 overflow-y-auto">
                    <div className="space-y-2 font-medium">
                        <NavLink to="/anime" className={ ({ isActive }) => "flex items-center px-2 py-2 text-sm text-center font-medium text-gray-900 dark:text-gray-100 border-b-2 hover:border-gray-700 dark:hover:border-gray-200 " + (isActive ? "border-indigo-500 hover:border-indigo-500 dark:hover:border-indigo-500" : "border-transparent") }>Аниме</NavLink>
                        <NavLink to="/manga" className={ ({ isActive }) => "flex items-center px-2 py-2 text-sm text-center font-medium text-gray-900 dark:text-gray-100 border-b-2 hover:border-gray-700 dark:hover:border-gray-200 " + (isActive ? "border-indigo-500 hover:border-indigo-500 dark:hover:border-indigo-500" : "border-transparent") }>Манга</NavLink>
                        <NavLink to="/ranobe" className={ ({ isActive }) => "flex items-center px-2 py-2 text-sm text-center font-medium text-gray-900 dark:text-gray-100 border-b-2 hover:border-gray-700 dark:hover:border-gray-200 " + (isActive ? "border-indigo-500 hover:border-indigo-500 dark:hover:border-indigo-500" : "border-transparent") }>Ранобэ</NavLink>
                    </div>
                </div>
            </div>
            <div onClick={toggleMenu} className={"bg-gray-900/50 fixed inset-0 z-30 "+ (menuOpen ? 'block' : 'hidden')} tabIndex="-1"></div>
            <div className="bg-white dark:bg-gray-950 shadow">
                <div className="px-8 mx-auto">
                    <div className="flex justify-between h-16 relative">
                        <div className="flex lg:hidden flex-row flex-1 justify-start items-center ">
                            <button type="button" onClick={toggleMenu} className="inline-flex rounded-full p-1 text-gray-900 dark:text-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 focus:outline-none" >
                                <svg className="w-6 h-6" fill="none" viewBox="0 0 32 32" aria-hidden="true">
                                    <path fill="currentColor" d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="hidden lg:flex flex-row flex-1 justify-start items-stretch">
                            <div className="flex flex-row space-x-3">
                                <NavLink to="/anime" className={ ({ isActive }) => "flex items-center px-2 py-2 text-sm text-center font-medium text-gray-900 dark:text-gray-100 border-b-2 hover:border-gray-700 dark:hover:border-gray-200 " + (isActive ? "border-indigo-500 hover:border-indigo-500 dark:hover:border-indigo-500" : "border-transparent") }>Аниме</NavLink>
                                <NavLink to="/manga" className={ ({ isActive }) => "flex items-center px-2 py-2 text-sm text-center font-medium text-gray-900 dark:text-gray-100 border-b-2 hover:border-gray-700 dark:hover:border-gray-200 " + (isActive ? "border-indigo-500 hover:border-indigo-500 dark:hover:border-indigo-500" : "border-transparent") }>Манга</NavLink>
                                <NavLink to="/ranobe" className={ ({ isActive }) => "flex items-center px-2 py-2 text-sm text-center font-medium text-gray-900 dark:text-gray-100 border-b-2 hover:border-gray-700 dark:hover:border-gray-200 " + (isActive ? "border-indigo-500 hover:border-indigo-500 dark:hover:border-indigo-500" : "border-transparent") }>Ранобэ</NavLink>
                            </div>
                        </div>
                        <div className="flex items-center ml-3">
                            <div className="flex flex-row justify-center space-x-3">
                                <a
                                    href="https://t.me/ElibFB2Media"
                                    className="inline-flex rounded-full p-1"
                                    target="_blank"
                                >
                                    <svg className="w-6 h-6" fill="none" viewBox="0 0 240.1 240.1" aria-hidden="true">
                                        <linearGradient id="tg-icon-bg" gradientUnits="userSpaceOnUse" x1="-838.041" y1="660.581" x2="-838.041" y2="660.3427" gradientTransform="matrix(1000 0 0 -1000 838161 660581)">
                                            <stop offset="0" style={{"stop-color":"#2AABEE"}}/>
                                            <stop offset="1" style={{"stop-color":"#229ED9"}}/>
                                        </linearGradient>
                                        <circle fill-rule="evenodd" clip-rule="evenodd" fill="url(#tg-icon-bg)" cx="120.1" cy="120.1" r="120.1"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M54.3,118.8c35-15.2,58.3-25.3,70-30.2 c33.3-13.9,40.3-16.3,44.8-16.4c1,0,3.2,0.2,4.7,1.4c1.2,1,1.5,2.3,1.7,3.3s0.4,3.1,0.2,4.7c-1.8,19-9.6,65.1-13.6,86.3 c-1.7,9-5,12-8.2,12.3c-7,0.6-12.3-4.6-19-9c-10.6-6.9-16.5-11.2-26.8-18c-11.9-7.8-4.2-12.1,2.6-19.1c1.8-1.8,32.5-29.8,33.1-32.3 c0.1-0.3,0.1-1.5-0.6-2.1c-0.7-0.6-1.7-0.4-2.5-0.2c-1.1,0.2-17.9,11.4-50.6,33.5c-4.8,3.3-9.1,4.9-13,4.8 c-4.3-0.1-12.5-2.4-18.7-4.4c-7.5-2.4-13.5-3.7-13-7.9C45.7,123.3,48.7,121.1,54.3,118.8z"/>
                                    </svg>
                                </a>
                                <Notifications />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;