import { humanFileSize } from "../../api"
import 'swiper/css'

function AnimesGridCardDownload({anime})
{
    return (
        <div className="flex flex-row w-full mx-auto">
            <a
                href={anime.download_path}
                download={ anime.download_path ? `${anime.name}.${anime.ext}` : null }
                className="
                    inline-flex flex-col
                    cursor-pointer
                    w-full
                    border-1 border-solid border-gray-100 rounded-lg
                    bg-white dark:bg-gray-800
                    shadow hover:shadow-lg dark:shadow-gray-800
                    transition-shadow
                "
            >
                <div className="flex pt-[133%] flex-col rounded-t-lg relative overflow-hidden">
                    <div className="absolute top-0 z-[3] flex flex-row items-center w-full">
                        <span className="inline-block mx-auto leading-4 text-sm text-center text-white dark:text-gray-300 bg-blue-600 dark:bg-blue-900 shadow-inner w-[60%] px-4 py-1 rounded-b-lg">Полнометражный</span>
                    </div>
                    { anime?.cover && (
                    <img className="absolute z-[1] top-0 left-0 w-full h-full object-cover object-center" src={`${anime?.cover}`} alt="" />
                    ) }
                </div>
                <div className="flex flex-col align-start mb-2 p-2">
                    <span className="text-gray-800 dark:text-gray-300 text-md leading-5 font-medium">{anime?.name}</span>
                    <span className="text-gray-800 dark:text-gray-300 text-sm leading-4 mt-2">{anime?.engname}</span>
                </div>
                <div className="flex flex-row rounded-b-lg hadow-inner dark:shadow-gray-800 mt-auto bg-gray-100 dark:bg-gray-700">
                    <span className="inline-flex basis-1/3 justify-center flex-grow text-gray-800 dark:text-gray-300 text-xs p-2">{anime?.quality}</span>
                    <span className="inline-flex basis-1/3 justify-center flex-grow text-gray-800 dark:text-gray-300 text-xs p-2 border-l border-gray-200 dark:border-gray-800">{anime?.voices}</span>
                    <span className="inline-flex basis-1/3 justify-center flex-grow text-gray-800 dark:text-gray-300 text-xs p-2 border-l border-gray-200 dark:border-gray-800">{humanFileSize(anime?.filesize)}</span>
                </div>
            </a>
        </div>
    )
}

export default AnimesGridCardDownload