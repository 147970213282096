import { useEffect } from 'react'
import { Link } from "react-router-dom"
import { notifications, updateNotifications, setLastReadNotification } from '../context/NotificationsContext'
import { useSignalEffect } from '@preact/signals-react'

function NotificationsElement(props)
{
    const { notification } = props

    let link = null
    let cover = null
    let target_text = null
    let subtarget_text = null

    const Anime = notification?.objects?.Anime
    const AnimeSeason = notification?.objects?.AnimeSeason
    const AnimeChapter = notification?.objects?.AnimeChapter
    const Manga = notification?.objects?.Manga
    const MangaVolume = notification?.objects?.MangaVolume
    const Ranobe = notification?.objects?.Ranobe
    const RanobeChapter = notification?.objects?.RanobeChapter

    if( !(notification?.target in notification?.objects) ) return;

    if( notification.target === 'Anime' )
    {
        let anime_slug = Anime?.slug

        cover = Anime?.cover
        link = anime_slug && `/anime/${anime_slug}`
        target_text = Anime?.name
    }
    else if( notification.target === 'AnimeSeason' )
    {
        if( AnimeSeason?.slug )
        {
            let anime_slug = Anime?.slug
            let season_slug = AnimeSeason?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}/${season_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name
        }
        else if( Anime?.slug )
        {
            let anime_slug = Anime?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name
        }
    }
    else if( notification.target === 'AnimeChapter' )
    {
        if( AnimeSeason?.slug )
        {
            let anime_slug = Anime?.slug
            let season_slug = AnimeSeason?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}/${season_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name + ' - Серия ' + AnimeChapter?.name
        }
        else if( Anime?.slug )
        {
            let anime_slug = Anime?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name + ' - Серия ' + AnimeChapter?.name
        }
    }
    else if( (notification.target === 'Manga' || notification.target === 'MangaVolume') )
    {
        let manga_slug = Manga?.slug

        cover = MangaVolume?.cover || Manga?.cover
        link = `/manga/${manga_slug}`
        target_text = Manga?.name

        if( notification.target === 'MangaVolume' && MangaVolume?.name )
        {
            subtarget_text = MangaVolume?.name
        }
    }
    else if( (notification.target === 'Ranobe' || notification.target === 'RanobeChapter') )
    {
        let ranobe_slug = Ranobe?.slug

        cover = RanobeChapter?.cover || Ranobe?.cover
        link = `/ranobe/${ranobe_slug}`
        target_text = Ranobe?.name

        if( notification.target === 'RanobeChapter' && RanobeChapter?.name )
        {
            subtarget_text = RanobeChapter?.name
        }
    }

    return notification && (
        <li className="flex flex-col">
            <Link to={link} className="flex flex-row items-start gap-3 p-3 bg-white shadow-md rounded-lg border border-gray-100">
                { cover && (
                <span className="flex flex-row w-24 shrink-0 items-start rounded-md overflow-hidden">
                    <img className="w-full object-cover object-center" src={cover} alt="" />
                </span>
                ) }
                <span className="flex flex-col items-start">
                    <span className="text-md text-gray-600 mb-2">{notification.datetime_time} {notification.datetime_date}</span>
                    <span className="text-md text-gray-600 mb-3">{notification?.type}</span>
                    { target_text && (
                    <span className="text-lg text-gray-900">{target_text}</span>
                    ) }
                    { subtarget_text && (
                    <span className="text-md text-gray-800 mt-1">{subtarget_text}</span>
                    ) }
                </span>
            </Link>
        </li>
    )
}

function UpdatesPage()
{
    useEffect( () => {
        updateNotifications()
    }, [])

    useSignalEffect( () => {
        notifications && setLastReadNotification()
    })

    return notifications?.value.length && (
        <ul className="flex flex-col w-full gap-6">
            { notifications?.value.map( (notification) => {
                return notification && (<NotificationsElement key={notification.id} notification={notification} />)
            }) }
        </ul>
    )
}

export default UpdatesPage