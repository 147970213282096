import { signal } from '@preact/signals'
import { API } from '../api'

const refresh_interval = 10000
let refresh_lock = false

firstRun()

function firstRun()
{
	let version = localStorage.getItem( 'version' )
	if( !version || version !== process.env.REACT_APP_VERSION )
	{
		localStorage.setItem( 'version', process.env.REACT_APP_VERSION )
		localStorage.removeItem( 'notifications' )
		localStorage.removeItem( 'notifications_timestamp' )
	}
}

export const notifications = signal(getNotificationsCache())

// Notifications data

export function getLastReadNotification()
{
    let value = localStorage.getItem( 'last_notification' )
    if(!value)
    {
        return 0
    }
    return parseInt(value)
}

export function setLastReadNotification()
{
    let last = notifications?.value ? notifications?.value[0] : null
    if(last && last?.id)
    {
        localStorage.setItem( 'last_notification', last?.id )
    }
}

export async function updateNotifications()
{
	let cached = getNotificationsCache()
	let timestamp = getNotificationsCacheTime()

	let now = Date.now()
	let refresh = timestamp + refresh_interval

	if( !cached.length || !timestamp || ( now > refresh ) )
	{
		await loadNotifications()
	}
}

function getNotificationsCache()
{
	let value = localStorage.getItem( 'notifications' )
	if(!value)
	{
		return []
	}
	return JSON.parse(value)
}

function getNotificationsCacheTime()
{
    let value = localStorage.getItem( 'notifications_timestamp' )
    if(!value)
    {
        return 0
    }
    return parseInt(value)
}


async function loadNotifications()
{
    if( refresh_lock ) return

	refresh_lock = true
    API.get( `/notifications` )
        .then( (response) => {
            localStorage.setItem( 'notifications', JSON.stringify(response.data) )
			localStorage.setItem( 'notifications_timestamp', Date.now() )
            notifications.value = response.data
            refresh_lock = false
        })
        .catch( (error) => {
            console.error(error)
        } )
}