import { signal, effect } from '@preact/signals'
import { API } from '../api'

const refresh_interval = 10000
let refresh_lock = false

firstRun()

function firstRun()
{
	let version = localStorage.getItem( 'version' )
	if( !version || version !== process.env.REACT_APP_VERSION )
	{
		localStorage.setItem( 'version', process.env.REACT_APP_VERSION )
		localStorage.removeItem( 'animes' )
		localStorage.removeItem( 'animes_timestamp' )
	}
}

export const animes = signal(getAnimesCache())
export const animesVisual = signal(getAnimesVisual())

// Animes visual

function getAnimesVisual()
{
	let value = localStorage.getItem( 'animesVisual' )
	if(!value)
	{
		return 'grid'
	}
	return value
}

// Animes data

export async function updateAnimes()
{
	let cached = getAnimesCache()
	let timestamp = getAnimesCacheTime()

	let now = Date.now()
	let refresh = timestamp + refresh_interval

	if( !cached.length || !timestamp || ( now > refresh ) )
	{
		await loadAnimes()
	}
}

function getAnimesCache()
{
	let value = localStorage.getItem( 'animes' )
	if(!value)
	{
		return []
	}
	return JSON.parse(value)
}

function getAnimesCacheTime()
{
	let value = localStorage.getItem( 'animes_timestamp' )
	if(!value)
	{
		return 0
	}
	return parseInt(value)
}

async function loadAnimes()
{
	if( refresh_lock ) return

	refresh_lock = true
	API.get( `/animes` )
		.then( (response) => {
			localStorage.setItem( 'animes', JSON.stringify(response.data) )
			localStorage.setItem( 'animes_timestamp', Date.now() )
			animes.value = response.data
			refresh_lock = false
		})
		.catch( (error) => {
			console.error(error)
		} )
}

// Save state
effect( () => {
	localStorage.setItem( 'animesVisual', animesVisual?.value )
}, [animesVisual])