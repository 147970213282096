import { signal, effect } from '@preact/signals'
import { API } from '../api'

const refresh_interval = 10000
let refresh_lock = false

firstRun()

function firstRun()
{
	let version = localStorage.getItem( 'version' )
	if( !version || version !== process.env.REACT_APP_VERSION )
	{
		localStorage.setItem( 'version', process.env.REACT_APP_VERSION )
		localStorage.removeItem( 'mangas' )
		localStorage.removeItem( 'mangas_timestamp' )
	}
}

export const mangas = signal(getMangasCache())
export const mangasVisual = signal(getMangasVisual())

// Mangas visual

function getMangasVisual()
{
	let value = localStorage.getItem( 'mangasVisual' )
	if(!value)
	{
		return 'grid'
	}
	return value
}

// Mangas data

export async function updateMangas()
{
	let cached = getMangasCache()
	let timestamp = getMangasCacheTime()

	let now = Date.now()
	let refresh = timestamp + refresh_interval

	if( !cached.length || !timestamp || ( now > refresh ) )
	{
		await loadMangas()
	}
}

function getMangasCache()
{
	let value = localStorage.getItem( 'mangas' )
	if(!value)
	{
		return []
	}
	return JSON.parse(value)
}

function getMangasCacheTime()
{
	let value = localStorage.getItem( 'mangas_timestamp' )
	if(!value)
	{
		return 0
	}
	return parseInt(value)
}

async function loadMangas()
{
	if( refresh_lock ) return

	refresh_lock = true
	API.get( `/mangas` )
		.then( (response) => {
			localStorage.setItem( 'mangas', JSON.stringify(response.data) )
			localStorage.setItem( 'mangas_timestamp', Date.now() )
			mangas.value = response.data
			refresh_lock = false
		})
		.catch( (error) => {
			console.error(error)
		} )
}

// Save state
effect( () => {
	localStorage.setItem( 'mangasVisual', mangasVisual?.value )
}, [mangasVisual])