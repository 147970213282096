import React from 'react'
import { humanFileSize } from '../../api'

function MangaVolumeRow(props)
{
    const { volume, currentManga } = props
    
    const download_name = `${currentManga?.name} - ${volume.name}.${volume.ext}`

    return (
        <a
            key={volume.id}
            href={volume.download_path}
            download={download_name}
            className="
                cursor-pointer
                flex flex-col
                w-full
                rounded-md
                bg-white hover:bg-gray-100
                border border-gray-300/75
                overflow-hidden
                group
            ">
            <span className="inline-flex items-stretch w-full overflow-hidden">
                <span
                    className="
                    inline-flex items-center justify-center
                    w-10 md:w-12
                    py-3 shrink-0
                    bg-white group-hover:bg-gray-800
                    text-gray-800 group-hover:text-gray-200
                    text-base font-medium
                    shadow-lg shadow-gray-300
                    overflow-hidden
                ">{volume.number}</span>
                <span
                    className="
                        inline-flex flex-col xl:flex-row
                        w-full p-3
                        gap-1
                    ">
                    <span className="inline-flex text-black text-sm md:text-base">{volume.name}</span>
                    <span className="hidden xl:inline-flex mx-1">
                        <span className="inline-flex text-gray-500 text-sm md:text-base">|</span>
                    </span>
                    <span className="inline-flex text-gray-500 text-sm md:text-base">{volume.engname}</span>
                </span>
            </span>
            { volume?.download_path && (
            <span
                className="
                    inline-flex flex-row
                    w-full
                    bg-gray-100
                    shadow-inner shadow-gray-300
                    group-hover:bg-gray-200
                ">
                <span
                    className="
                        inline-flex justify-center
                        basis-1/2 p-1
                        text-sm md:text-base uppercase
                        text-gray-800
                ">{volume?.ext}</span>
                <span
                    className="
                        inline-flex justify-center
                        basis-1/2 p-1
                        text-sm md:text-base uppercase
                        text-gray-800
                        border-l border-black/10
                ">{ humanFileSize(volume?.filesize||0, 0) }</span>
            </span>
            ) }
        </a>
    )
}

export default MangaVolumeRow