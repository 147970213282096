import Main from './theme/Main'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
// 
import UnderDevelopmentPage from './pages/UnderDevelopmentPage'
// 
import AnimeMainPage from './pages/AnimeMainPage'
import AnimeSeasonsPage from './pages/AnimeSeasonsPage'
import AnimeSeasonPage from './pages/AnimeSeasonPage'
// 
import MangaMainPage from './pages/MangaMainPage'
import MangaPage from './pages/MangaPage'
// 
import UpdatesPage from './pages/UpdatesPage'

function App()
{
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Main />}>
					<Route index path="/" element={<Navigate to="/anime" />} />
					<Route path="/anime" element={<AnimeMainPage />}></Route>
					<Route path="/anime/:anime_slug" element={<AnimeSeasonsPage />}></Route>
					<Route path="/anime/:anime_slug/:season_slug" element={<AnimeSeasonPage />}></Route>
					<Route path="/manga" element={<MangaMainPage />}></Route>
					<Route path="/manga/:manga_slug" element={<MangaPage />}></Route>
					<Route path="/ranobe" element={<UnderDevelopmentPage />}></Route>
					<Route path="/updates" element={<UpdatesPage />} />
					<Route path="*" element={<Navigate to="/anime" />} />
				</Route>
				<Route path="*" element={<Navigate to="/anime" />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App;
