import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSignalEffect } from '@preact/signals-react'
import { Link, useParams } from 'react-router-dom'
import { humanFileSize } from '../api'
import {
    animes,
    animesVisual,
    updateAnimes
} from "../context/AnimesContext"
import Fuse from 'fuse.js'
// 
import AnimeSeasonsGrid from "../visual/anime/AnimeSeasonsGrid"
import AnimeSeasonsList from "../visual/anime/AnimeSeasonsList"

function AnimeSeasonsPage()
{
    const params = useParams()
    const { anime_slug } = params

    const [searchVerb, setSearchVerb] = useState('')
    const [seasons, setSeasons] = useState([])
    const [currentAnime, setCurrentAnime] = useState(null)
    const [filteredSeasons, setFilteredSeasons] = useState(seasons)
    
    const fuse = useMemo( () => {
        return new Fuse(seasons, {
            keys: ['name', 'engname'],
            threshold: 0.3,
            ignoreLocation: true,
        })
    }, [seasons])

    function handleInputChange(e)
    {
        setSearchVerb(e.target.value)
    }

    const filterCurrentAnime = useCallback( () => {
        let current = anime_slug ? animes?.value.filter( (anime) => { return anime.slug === anime_slug} ).shift() : null
        setCurrentAnime(current)
    }, [anime_slug])

    const filter = useCallback( () => {
        if( searchVerb.length === 0 )
        {
            setFilteredSeasons(seasons)
            return
        }

        const results = fuse.search(searchVerb);
        const items = results.map((result) => result.item)
        setFilteredSeasons(items)
    }, [fuse,seasons,searchVerb])
    
    useEffect( () => {
        filterCurrentAnime()
    }, [anime_slug,filterCurrentAnime])

    useSignalEffect( () => {
        animes && filterCurrentAnime()
    })

    useEffect( () => {
        if( !currentAnime ) return
        setSeasons(currentAnime.seasons)
    }, [currentAnime])

    useEffect( () => {
        filter()
    }, [seasons,searchVerb,filter])

    useEffect( () => {
        updateAnimes()
    }, [])

    return (
        <div className="flex flex-col items-center w-full">

            <div className="flex flex-row w-full max-w-screen-2xl mb-4">
                <Link to={'/anime'} className="inline-flex p-3 rounded-lg shadow bg-white text-gray-800 dark:text-gray-200 dark:bg-gray-950 border-1 border-gray-200 dark:border-gray-800 gap-1"> <span className="-scale-x-100">➜</span> Назад</Link>
            </div>

            <div className="flex flex-row w-full max-w-screen-2xl gap-3 bg-white dark:bg-gray-950 rounded-lg mb-6 overflow-hidden shadow-lg">
                <div className="flex flex-col p-2 md:p-3">
                    <span className="text-xl md:text-3xl text-black">{ currentAnime?.name }</span>
                    <span className="text-lg md:text-2xl text-gray-600">{ currentAnime?.engname }</span>
                    <span className="text-sm md:text-base text-gray-500 mt-3">{ humanFileSize(currentAnime?.foldersize||0) }</span>
                </div>
            </div>

            <div
            className="
                flex flex-row
                w-full max-w-screen-2xl
                mb-6
                bg-gray-100 dark:bg-gray-900
                "
            >
                <input
                    type="search"
                    className="
                        block w-full p-2
                        rounded-lg shadow
                        border-0 outline-none
                        text-gray-900 placeholder:text-gray-400
                        "
                    onChange={handleInputChange}
                    placeholder="Поиск"
                />
            </div>
            { filteredSeasons.length && (
                animesVisual.value === 'grid'
                ?
                <AnimeSeasonsGrid anime={currentAnime} seasons={filteredSeasons}/>
                :
                <AnimeSeasonsList anime={currentAnime} seasons={filteredSeasons}/>
            ) }
        </div>
    )
}

export default AnimeSeasonsPage