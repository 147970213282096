import React from 'react'
import { humanFileSize } from '../../api'

function AnimeChapterRow(props)
{
    const { chapter, currentSeason, currentAnime } = props

    const chapter_name_digital = parseInt(chapter.name)
    
    const chapter_name = chapter_name_digital === chapter.number ? chapter.name : `${chapter.number} - ${chapter.name}`
    
    const download_name = currentAnime?.seasons?.length === 1 ? `${currentAnime?.name} - ${chapter_name}.${chapter.ext}` : `${currentAnime?.name} ${currentSeason.number} - ${chapter_name}.${chapter.ext}`


    return (
        <a
            key={chapter.id}
            href={chapter.download_path}
            download={download_name}
            className="
                cursor-pointer
                flex flex-col
                w-full
                rounded-md
                bg-white hover:bg-gray-100
                border border-gray-300/75
                overflow-hidden
                group
            ">
            <span className="inline-flex items-stretch w-full overflow-hidden">
                <span
                    className="
                    inline-flex items-center justify-center
                    w-10 md:w-12
                    py-3 shrink-0
                    bg-white group-hover:bg-gray-800
                    text-gray-800 group-hover:text-gray-200
                    text-base font-medium
                    shadow-lg shadow-gray-300
                    overflow-hidden
                ">{chapter.number}</span>
                <span
                    className="
                        inline-flex flex-col xl:flex-row
                        w-full p-3
                        gap-1
                    ">
                    <span className="inline-flex text-black text-sm md:text-base">{chapter.name}</span>
                    <span className="hidden xl:inline-flex mx-1">
                        <span className="inline-flex text-gray-500 text-sm md:text-base">|</span>
                    </span>
                    <span className="inline-flex text-gray-500 text-sm md:text-base">{chapter.engname}</span>
                </span>
            </span>
            { chapter?.download_path && (
            <span
                className="
                    inline-flex flex-row
                    w-full
                    bg-gray-100
                    shadow-inner shadow-gray-300
                    group-hover:bg-gray-200
                ">
                <span
                    className="
                        inline-flex justify-center
                        basis-1/2 p-1
                        text-sm md:text-base uppercase
                        text-gray-800
                ">{chapter?.ext}</span>
                { currentSeason?.hevc && (
                <span
                    className="
                        inline-flex justify-center
                        basis-1/2 p-1
                        text-sm md:text-base uppercase
                        text-gray-800
                        border-l border-black/10
                ">HEVC</span>
                ) }
                <span
                    className="
                        inline-flex justify-center
                        basis-1/2 p-1
                        text-sm md:text-base uppercase
                        text-gray-800
                        border-l border-black/10
                ">{ humanFileSize(chapter?.filesize||0, 0) }</span>
            </span>
            ) }
        </a>
    )
}

export default AnimeChapterRow