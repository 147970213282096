
function UnderDevelopmentPage()
{
    return (
        <div className="flex flex-col items-center w-full">
            <div
            className="
                flex flex-row justify-center
                w-full max-w-screen-2xl
                my-6
                text-4xl font-medium
                "
            >
                Функционал в разработке
            </div>
        </div>
    )
}

export default UnderDevelopmentPage;