import React, { useState, useEffect, useCallback, useRef } from 'react'
import { humanFileSize } from '../api'
import { useSignalEffect } from '@preact/signals-react'
import { Link, useParams } from 'react-router-dom'
import {
    animes,
    updateAnimes
} from "../context/AnimesContext"
// 
import AnimeChapterRow from '../visual/anime/AnimeChapterRow'

function AnimeSeasonsPage()
{
    const params = useParams()
    const { anime_slug, season_slug } = params

    const [seasons, setSeasons] = useState([])
    const [chapters, setChapters] = useState([])
    const [currentAnime, setCurrentAnime] = useState(null)
    const [currentSeason, setCurrentSeason] = useState(null)

    const ref = useRef()

    function downloadAll()
    {
        if(!ref.current) return

        let links = ref.current.querySelectorAll('a[download]')
        links.forEach( (link) => {
            link.click()
        } )
    }

    const filterCurrentAnime = useCallback( () => {
        let current = anime_slug ? animes?.value.filter( (anime) => { return anime.slug === anime_slug} ).shift() : null
        setCurrentAnime(current)
    }, [anime_slug])

    useEffect( () => {
        updateAnimes()
    }, [])
    
    useEffect( () => {
        filterCurrentAnime()
    }, [anime_slug,filterCurrentAnime])

    useSignalEffect( () => {
        animes && filterCurrentAnime()
    })

    useEffect( () => {
        if( !currentAnime ) return
        setSeasons(currentAnime.seasons)
    }, [currentAnime])

    useEffect( () => {
        let current = season_slug ? seasons.filter( (season) => { return season.slug === season_slug} ).shift() : null
        setCurrentSeason(current)
    }, [seasons,season_slug])

    useEffect( () => {
        if( !currentSeason ) return
        setChapters(currentSeason.chapters)
    }, [currentSeason])

    let BackLink = currentAnime?.seasons?.length > 1 ? `/anime/${currentAnime.slug}` : `/anime/`

    return (
        <div className="flex flex-col items-center w-full">

            <div className="flex flex-row w-full max-w-screen-2xl mb-4">
                <Link to={BackLink} className="inline-flex p-3 rounded-lg shadow bg-white text-gray-800 border border-gray-200 gap-1"> <span className="-scale-x-100">➜</span> Назад</Link>
            </div>

            <div className="flex flex-col w-full max-w-screen-2xl gap-3 mb-8">

                <div className="flex flex-col w-full bg-white rounded-lg overflow-hidden shadow-lg p-2 md:p-3">
                    <span className="text-xl md:text-3xl text-black">{ currentAnime?.name }</span>
                    <span className="text-lg md:text-2xl text-gray-600">{ currentAnime?.engname }</span>
                    { currentAnime?.seasons?.length > 1 && (
                        <span className="text-sm md:text-base text-gray-500 mt-3">{ currentSeason?.name } | { currentSeason?.engname }</span>
                    )}
                    <span className="text-sm md:text-base text-gray-500 mt-3">{ currentSeason?.voices } | { currentSeason?.quality }</span>
                    { currentSeason?.foldersize && (
                    <span className="text-sm md:text-base text-gray-500 mt-3">{ humanFileSize(currentSeason?.foldersize) }</span>
                    )}
                </div>

                <div className="inline-flexmb-3">
                    <button
                        onClick={downloadAll}
                        className="
                            inline-flex justify-center
                            w-full p-3
                            rounded-lg
                            shadow-md
                            bg-zinc-300 hover:bg-gray-800
                            text-gray-800 hover:text-gray-200
                            border border-zinc-300 hover:border-gray-800
                    ">Скачать все</button>
                </div>
        
            </div>

            <div className="flex flex-col items-center w-full max-w-screen-2xl gap-4 md:gap-6" ref={ref}>
                { chapters && chapters.map( (chapter) => <AnimeChapterRow key={chapter.id} chapter={chapter} currentSeason={currentSeason} currentAnime={currentAnime} /> ) }
            </div>
        </div>
    )
}

export default AnimeSeasonsPage