import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSignalEffect } from '@preact/signals-react'
import {
    animes,
    updateAnimes
} from "../context/AnimesContext"
import Fuse from 'fuse.js'
// 
import AnimesGrid from "../visual/anime/AnimesGrid"

function AnimeMainPage()
{
    const [searchVerb, setSearchVerb] = useState('')
    const [filteredAnimes, setFilteredAnimes] = useState(animes?.value)
    
    const fuse = useMemo( () => {
        return new Fuse(animes?.value, {
            keys: ['name', 'engname'],
            threshold: 0.3,
            ignoreLocation: true,
        })
    }, [])

    function handleInputChange(e)
    {
        setSearchVerb(e.target.value)
    }

    const filter = useCallback( () => {
        if( searchVerb.length === 0 )
        {
            setFilteredAnimes(animes?.value)
            return
        }

        const results = fuse.search(searchVerb);
        const items = results.map((result) => result.item)
        setFilteredAnimes(items)
    }, [fuse,searchVerb])

    useEffect( () => {
        filter()
    }, [searchVerb,filter])

    useSignalEffect( () => {
        animes && filter()
    })

    useEffect( () => {
        updateAnimes()
    }, [])

    return (
        <div className="flex flex-col items-center w-full">
            <div
            className="
                flex flex-row
                w-full max-w-screen-2xl
                mb-6
                bg-gray-100 dark:bg-gray-900
                "
            >
                <input
                    type="search"
                    className="
                        block w-full p-2
                        rounded-lg shadow
                        border-0 outline-none
                        text-gray-900 placeholder:text-gray-400
                        "
                    onChange={handleInputChange}
                    placeholder="Поиск"
                />
            </div>
            { filteredAnimes.length && (
                <AnimesGrid animes={filteredAnimes}/>
            ) }
        </div>
    )
}

export default AnimeMainPage