import React, { useState, useEffect, useCallback, useRef } from 'react'
import { humanFileSize } from '../api'
import { useSignalEffect } from '@preact/signals-react'
import { Link, useParams } from 'react-router-dom'
import {
    mangas,
    updateMangas
} from "../context/MangasContext"
// 
import MangaVolumeRow from '../visual/manga/MangaVolumeRow'

function MangaPage()
{
    const params = useParams()
    const { manga_slug } = params

    const [volumes, setChapters] = useState([])
    const [currentManga, setCurrentManga] = useState(null)

    const ref = useRef()

    function downloadAll()
    {
        if(!ref.current) return

        let links = ref.current.querySelectorAll('a[download]')
        links.forEach( (link) => {
            link.click()
        } )
    }

    const filterCurrentManga = useCallback( () => {
        let current = manga_slug ? mangas?.value.filter( (manga) => { return manga.slug === manga_slug} ).shift() : null
        setCurrentManga(current)
    }, [manga_slug])

    useEffect( () => {
        updateMangas()
    }, [])
    
    useEffect( () => {
        filterCurrentManga()
    }, [manga_slug,filterCurrentManga])

    useSignalEffect( () => {
        mangas && filterCurrentManga()
    })

    useEffect( () => {
        if( !currentManga ) return
        setChapters(currentManga.volumes)
    }, [currentManga])

    let BackLink = `/manga/`

    return (
        <div className="flex flex-col items-center w-full">

            <div className="flex flex-row w-full max-w-screen-2xl mb-4">
                <Link to={BackLink} className="inline-flex p-3 rounded-lg shadow bg-white text-gray-800 border border-gray-200 gap-1"> <span className="-scale-x-100">➜</span> Назад</Link>
            </div>

            <div className="flex flex-col w-full max-w-screen-2xl gap-3 mb-8">

                <div className="flex flex-col w-full bg-white rounded-lg overflow-hidden shadow-lg p-2 md:p-3">
                    <span className="text-xl md:text-3xl text-black">{ currentManga?.name }</span>
                    <span className="text-lg md:text-2xl text-gray-600">{ currentManga?.engname }</span>
                    <span className="text-sm md:text-base text-gray-500 mt-3">{ currentManga?.author } | {currentManga?.status }</span>
                    { currentManga?.foldersize && (
                    <span className="text-sm md:text-base text-gray-500 mt-3">{ humanFileSize(currentManga?.foldersize) }</span>
                    )}
                </div>

                <div className="inline-flexmb-3">
                    <button
                        onClick={downloadAll}
                        className="
                            inline-flex justify-center
                            w-full p-3
                            rounded-lg
                            shadow-md
                            bg-zinc-300 hover:bg-gray-800
                            text-gray-800 hover:text-gray-200
                            border border-zinc-300 hover:border-gray-800
                    ">Скачать все</button>
                </div>
        
            </div>

            <div className="flex flex-col items-center w-full max-w-screen-2xl gap-4 md:gap-6" ref={ref}>
                { volumes && volumes.map( (volume) => <MangaVolumeRow key={volume.id} volume={volume} currentManga={currentManga} /> ) }
            </div>
        </div>
    )
}

export default MangaPage