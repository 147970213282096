import { Link } from "react-router-dom"
import { humanFileSize } from "../../api"

function AnimeSeasonsGridCard({anime,season})
{
    return (
        <div className="flex flex-row w-full mx-auto">
            <Link
                to={ `/anime/${anime?.slug}/${season.slug}/` }
                className="
                    inline-flex flex-col
                    cursor-pointer
                    w-full
                    border-1 border-solid border-gray-100 rounded-lg
                    bg-white
                    shadow hover:shadow-lg
                    transition-shadow
                "
            >
                <div className="flex pt-[133%] flex-col rounded-t-lg relative overflow-hidden">
                    { season?.cover && (
                    <img className="absolute z-[1] top-0 left-0 w-full h-full object-cover object-center" src={`${season?.cover}`} alt="" />
                    ) }
                </div>
                <div className="flex flex-col align-start mb-2 p-2">
                    <span className="text-md text-gray-800 leading-5 font-medium">{season?.name}</span>
                    <span className="text-sm text-gray-800 leading-4 mt-2">{season?.engname}</span>
                </div>
                <div className="flex flex-row rounded-b-lg shadow-inner mt-auto bg-gray-100">
                    <span
                        className="
                            inline-flex justify-center
                            basis-1/3
                            text-gray-800
                            text-xs p-2
                    ">{season?.quality}</span>
                    <span
                        className="
                            inline-flex justify-center
                            basis-1/3
                            text-gray-800
                            text-xs p-2
                            border-l border-black/10
                    ">{season?.voices}</span>
                    <span
                        className="
                            inline-flex justify-center
                            basis-1/3
                            text-gray-800
                            text-xs p-2
                            border-l border-black/10
                    ">{humanFileSize(season?.foldersize)}</span>
                </div>
            </Link>
        </div>
    )
}

export default AnimeSeasonsGridCard