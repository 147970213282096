import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import 'swiper/css'

function AnimesGridCard({anime})
{
    return (
        <div className="flex flex-row w-full mx-auto">
            <Link
                to={ anime?.seasons?.length === 1 ? `/anime/${anime.slug}/${anime?.seasons[0].slug}` : `/anime/${anime.slug}/` }
                className="
                    inline-flex flex-col
                    cursor-pointer
                    w-full
                    border-1 border-solid border-gray-100 rounded-lg
                    bg-white dark:bg-gray-800
                    shadow hover:shadow-lg dark:shadow-gray-800
                    transition-shadow
                "
            >
                <div className="flex pt-[133%] flex-col rounded-t-lg relative overflow-hidden">
                    { anime?.download_path && (
                    <div className="absolute top-0 z-[3] flex flex-row items-center w-full">
                        <span className="inline-block mx-auto leading-4 text-sm text-center text-white bg-blue-600 shadow-inner w-[60%] px-4 py-1 rounded-b-lg">Полнометражный</span>
                    </div>
                    )}
                    { anime?.covers.length > 1 ? (
                        <div className="absolute z-[1] top-0 left-0 w-full h-full">
                            <Swiper
                                modules={[Autoplay]}
                                className="w-full h-full"
                                spaceBetween={0}
                                slidesPerView={1}
                                speed={1000}
                                autoplay={{delay: 2000, pauseOnMouseEnter: true}}
                                loop={true}
                            >
                                { anime?.covers.map( (cover) => {
                                    return (
                                        <SwiperSlide key={cover}>
                                            <img className="w-full h-full object-cover object-center" src={`${cover}`} alt="" />
                                        </SwiperSlide>
                                    )
                                } ) }
                            </Swiper>
                        </div>
                    ) : (
                        anime?.cover && (
                        <img className="absolute z-[1] top-0 left-0 w-full h-full object-cover object-center" src={`${anime?.cover}`} alt="" />
                        )
                    ) }
                </div>
                <div className="flex flex-col align-start mb-2 p-2">
                    <span className="text-gray-800 dark:text-gray-300 text-md leading-5 font-medium">{anime?.name}</span>
                    <span className="text-gray-800 dark:text-gray-300 text-sm leading-4 mt-2">{anime?.engname}</span>
                </div>
                <div className="flex flex-row rounded-b-lg shadow-inner dark:shadow-gray-800 mt-auto bg-gray-100 dark:bg-gray-700">
                    <span className="inline-flex basis-1/2 justify-center flex-grow text-gray-800 dark:text-gray-300 text-xs p-2">{anime?.quality}</span>
                    <span className="inline-flex basis-1/2 justify-center flex-grow text-gray-800 dark:text-gray-200 text-xs p-2 border-l border-gray-200 dark:border-gray-800">{anime?.voices}</span>
                </div>
            </Link>
        </div>
    )
}

export default AnimesGridCard