import { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import { notifications, updateNotifications, getLastReadNotification, setLastReadNotification } from '../context/NotificationsContext'
import { useSignalEffect } from '@preact/signals-react'

const useOutsideClick = (callback) => {
    const ref = useRef()
  
    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }
    
        document.addEventListener('click', handleClick, true)
    
        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [ref,callback])
  
    return ref
}

function NotificationsElement(props)
{
    const { notification } = props

    let link = null
    let cover = null
    let target_text = null
    let subtarget_text = null

    const Anime = notification?.objects?.Anime
    const AnimeSeason = notification?.objects?.AnimeSeason
    const AnimeChapter = notification?.objects?.AnimeChapter
    const Manga = notification?.objects?.Manga
    const MangaVolume = notification?.objects?.MangaVolume
    const Ranobe = notification?.objects?.Ranobe
    const RanobeChapter = notification?.objects?.RanobeChapter

    if( !(notification?.target in notification?.objects) ) return

    if( notification.target === 'Anime' )
    {
        let anime_slug = Anime?.slug

        cover = Anime?.cover
        link = anime_slug ? `/anime/${anime_slug}` : '/anime/'
        target_text = Anime?.name
    }
    else if( notification.target === 'AnimeSeason' )
    {
        if( AnimeSeason?.slug )
        {
            let anime_slug = Anime?.slug
            let season_slug = AnimeSeason?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}/${season_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name
        }
        else if( Anime?.slug )
        {
            let anime_slug = Anime?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name
        }
    }
    else if( notification.target === 'AnimeChapter' )
    {
        if( AnimeSeason?.slug )
        {
            let anime_slug = Anime?.slug
            let season_slug = AnimeSeason?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}/${season_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name + ' - Серия ' + AnimeChapter?.name
        }
        else if( Anime?.slug )
        {
            let anime_slug = Anime?.slug

            cover = AnimeSeason?.cover || Anime?.cover
            link = `/anime/${anime_slug}`
            target_text = Anime?.name
            subtarget_text = AnimeSeason?.name + ' - Серия ' + AnimeChapter?.name
        }
    }
    else if( (notification.target === 'Manga' || notification.target === 'MangaVolume') )
    {
        let manga_slug = Manga?.slug

        cover = MangaVolume?.cover || Manga?.cover
        link = `/manga/${manga_slug}`
        target_text = Manga?.name

        if( notification.target === 'MangaVolume' && MangaVolume?.name )
        {
            subtarget_text = MangaVolume?.name
        }
    }
    else if( (notification.target === 'Ranobe' || notification.target === 'RanobeChapter') )
    {
        let ranobe_slug = Ranobe?.slug

        cover = RanobeChapter?.cover || Ranobe?.cover
        link = `/ranobe/${ranobe_slug}`
        target_text = Ranobe?.name

        if( notification.target === 'RanobeChapter' && RanobeChapter?.name )
        {
            subtarget_text = RanobeChapter?.name
        }
    }

    return notification && (
        <li className="flex flex-col">
            <Link to={link} className="flex flex-row items-start gap-2 p-2">
                { cover && (
                <span className="flex flex-row w-16 shrink-0 items-start rounded-md overflow-hidden">
                    <img className="w-full object-cover object-center" src={cover} alt="" />
                </span>
                ) }
                <span className="flex flex-col items-start">
                    <span className="text-xs text-gray-600 mb-1">{notification.datetime_time} {notification.datetime_date}</span>
                    <span className="text-xs text-gray-600 mb-2">{notification?.type}</span>
                    { target_text && (
                    <span className="text-sm text-gray-900">{target_text}</span>
                    ) }
                    { subtarget_text && (
                    <span className="text-sm text-gray-700">{subtarget_text}</span>
                    ) }
                </span>
            </Link>
        </li>
    )
}

function Notifications()
{
    const [open, setOpen] = useState(false)
    const [last, setLast] = useState(notifications?.value.slice(0,10) || [])
    const [lastRead, setLastRead] = useState(getLastReadNotification())
    
    const setClose = () => {
        setOpen(false)
    }
    const ref = useOutsideClick(setClose)

    function unreadMarker()
    {
        if (last?.length && last[0]?.id > lastRead)
        {
            return (<span className="absolute top-1 right-1 w-3 h-3 border-2 border-white rounded-full bg-green-500"></span>)
        }
        else
        {
            return null
        }
    }

    function setLastVisible()
    {
        setLast( notifications?.value.slice(0,10) || [] )
    }

    useEffect( () => {
        updateNotifications()
    }, [])

    useEffect( () => {
        open && setLastReadNotification()
        setLastRead(getLastReadNotification())
    }, [open])

    useSignalEffect( () => {
        notifications && setLastVisible()
    })

    return (
        <div className="inline-flex relative" ref={ref}>
            <button onClick={ ()=>setOpen(!open) } className={"inline-flex rounded-full p-1 hover:text-gray-800 relative focus:outline-none " + (open?"text-gray-800":"text-gray-400")}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" fill="none" className="text-inherit w-7 h-7">
                    <path fill="currentColor" d="M512 938.666667c47.146667 0 85.333333-38.186667 85.333333-85.333334h-170.666666c0 47.146667 38.186667 85.333333 85.333333 85.333334z m256-256V469.333333c0-131.2-69.76-240.64-192-269.653333V170.666667c0-35.413333-28.586667-64-64-64s-64 28.586667-64 64v29.013333c-122.24 29.013333-192 138.453333-192 269.653333v213.333334l-85.333333 85.333333v42.666667h682.666666v-42.666667l-85.333333-85.333333z" />
                </svg>
                { unreadMarker() }
            </button>
            <div className={ "absolute right-1 top-full z-20 w-72 md:w-80 bg-white rounded-lg border border-gray-200 shadow-lg " + (open ? "block" : "hidden")}>
                { last?.length && (
                    <ul className="flex flex-col divide-y divide-gray-300 divide-dashed max-h-[80vh] overflow-y-auto">
                        { last.map( (notification) => {
                            return notification && (<NotificationsElement key={notification.id} notification={notification} />)
                        }) }
                    </ul>
                ) }
                <Link
                    onClick={setClose}
                    className="
                        inline-flex items-center justify-center
                        w-full p-2
                        text-sm text-center text-gray-400 hover:text-gray-600
                        bg-gray-100 hover:bg-gray-200
                        rounded-b-lg
                    "
                    to={'/updates'}
                >Смотреть все</Link>
            </div>
        </div>
    )
}

export default Notifications