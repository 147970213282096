import AnimeSeasonsGridCard from './AnimeSeasonsGridCard'
import AnimeSeasonsGridCardDownload from './AnimeSeasonsGridCardDownload'

function AnimeSeasonsGrid({anime,seasons})
{
    return (
        <div
            className="
                grid
                w-full max-w-screen-2xl
                grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5
                gap-6
                "
        >
            { seasons?.length ? seasons.map( (season) => { return (
                season?.download_path ? <AnimeSeasonsGridCardDownload key={season.id} anime={anime} season={season} /> : <AnimeSeasonsGridCard key={season.id} anime={anime} season={season} />
            ) } ) : (
                <span className="col-span-full text-lg text-gray-600 text-center">Ничего не найдено</span>
            ) }
        </div>
    )
}

export default AnimeSeasonsGrid