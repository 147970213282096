// import mem from "mem"
import axios from 'axios'
// import { session } from '../contexts/auth'

export const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
})

export function humanFileSize(bytes, dp=2)
{
    const delimeter = 1024;
  
    if( Math.abs(bytes) < delimeter )
    {
        return bytes + ' Байт'
    }
  
    const units = ['Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Эб', 'Зб', 'Йб']
    let u = -1
    const r = 10**dp
  
    while( Math.round(Math.abs(bytes) * r) / r >= delimeter && u < units.length - 1 )
    {
        bytes /= delimeter
        ++u;
    }

    return bytes.toFixed(dp) + ' ' + units[u];
}

export function pluralize(forms, n)
{
    let idx;
    if( n % 10 === 1 && n % 100 !== 11 )
    {
        idx = 0
    }
    else if( n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) )
    {
        idx = 1
    }
    else
    {
        idx = 2
    }
    return n + ' ' + (forms[idx] || '')
}

// async function RefreshToken() {
//     let ses = session.value

//     return RAW
//         .post("/auth/refresh", {}, {
//                 headers: {
//                     authorization: `Bearer ${ses?.refresh_token}`,
//                 }
//         })
//         .then( (response) => {
//             let new_ses = Object.assign(ses, response.data)            
//             session.value = new_ses
//             return Promise.resolve(session.value)
//         })
//         .catch( (err) => {
//             session.value = {}
//             return Promise.resolve(session.value)
//         })
// }

// const memoizedRefreshToken = mem(RefreshToken, { 'maxAge': 5000 })

// API.interceptors.request.use(
//     async (config) => {
    
//         let ses = session.value

//         if (ses?.access_token) {
//             config.headers = {
//                 ...config.headers,
//                 authorization: `Bearer ${ses?.access_token}`,
//             }
//         }
//         return config
//     },
//     (error) => Promise.reject(error)
// )

// API.interceptors.response.use(
//     (response) => response,
//     async (error) => {

//         const config = error?.config

//         if( (error?.response?.status === 401 || error?.response?.status === 422) && !config?.sent)
//         {
//             config.sent = true
//             const result = await memoizedRefreshToken()
//             if (result?.access_token)
//             {
//                 config.headers = {
//                     ...config.headers,
//                     authorization: `Bearer ${result?.access_token}`,
//                 }
//             }
//             return axios(config)
//         }
//         return Promise.reject(error)
//     }
// )