import AnimesGridCard from './AnimesGridCard'
import AnimesGridCardDownload from './AnimesGridCardDownload'

function AnimesGrid({animes})
{
    return (
        <div
            className="
                grid
                w-full max-w-screen-2xl
                grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5
                gap-6
                "
        >
            { animes?.length ? animes.map( (anime) => { return (
                anime?.download_path ? <AnimesGridCardDownload key={anime.id} anime={anime} /> : <AnimesGridCard key={anime.id} anime={anime} />
            ) } ) : (
            <span className="col-span-full text-lg text-gray-600 text-center">Ничего не найдено</span>
            ) }
        </div>
    )
}

export default AnimesGrid